<template>
    <div>
        <b-card no-body class="border mt-1">
        <b-card-header class="p-1">
      <b-card-title>{{this.$t("curlang")=="en"?'Quest List':'Lista de Desafios'}}</b-card-title>
    </b-card-header>
    <b-table
    :items="quests"
    :busy="busy"
      :fields="fields"
      responsive
      hover
      selectable
      select-mode="single"
       @row-clicked="getId"
       > 
       <template #table-busy>
        <div class="text-center text-success my-2">
          <b-spinner class="align-middle"></b-spinner>
        </div>
      </template>
       <template #cell(options.items.length)="data">
         <b-badge
          pill
          variant="warning"
          v-if="data.value>0"
        >
           Update Items
        </b-badge>
        <b-badge
          pill
          variant="success"
          v-else
        >
           Add Items
        </b-badge>
       </template>
       <template #cell(options.description)="data">
        <div v-html="data.value"></div>
       </template>
    </b-table>
        </b-card>
        <b-card no-body class="border mt-1" v-if="seeItem" :key="changeQuest">
            <b-card-header class="p-1">
                <b-card-title> {{$t('challangeregister.challangeregister')+" "+BaritemTitle}} </b-card-title>
            </b-card-header>
            <b-card-body>
                  <b-form
        ref="form"
        :style="{height: trHeight}"
        class="repeater-form"
        @submit.prevent="insertItems"
      >
        <!-- Row Loop -->
        <b-row
          v-for="(item, index) in items.options.items"
          :id="item.id"
          :key="item.id"
          ref="row"
        >
          <!-- Item Name -->
          <b-col lg="3" md="6" sm="12">
            <b-form-group
              :label="$t('challangeregister.itemtitle')"
            >
              <b-form-input
              v-model="items.options.items[index].title"
                type="text"
                :placeholder="$t('challangeregister.itemtitle')"
                :state="items.options.items[index].title==''?false:true"
              />
            </b-form-group>
          </b-col>
           <!--b-col lg="3" md="6" sm="12">
            <b-form-group
              :label="$t('challangeregister.itemtitlees')"
            >
              <b-form-input
              v-model="items.options.items[index].titlees"
                type="text"
                :placeholder="$t('challangeregister.itemtitlees')"
                :state="items.options.items[index].titlees==''?false:true"
              />
            </b-form-group>
          </b-col-->
          <!-- Cost -->
          <b-col lg="3" md="6" sm="12">
            <b-form-group
              :label="$t('challangeregister.rewards')"
            >
              <b-form-input
              v-model="items.options.items[index].rewards"
                type="number"
                :placeholder="$t('challangeregister.rewards')"
                :state="items.options.items[index].rewards==''?false:true"
              />
            </b-form-group>
          </b-col>

           <b-col lg="3" md="6" sm="12">
         <b-form-group
         :label="$t('challangeregister.type')">
         <b-form-select
      v-model="items.options.items[index].type.value"
      :options="option"
      :state="items.options.items[index].type.value=== 0 ? false : true"
      @change="openModal(index)"
    />
      </b-form-group>
      </b-col>
        <b-col lg="3" md="6" sm="12">
         <b-form-group
         label="Select a Coin">
         <b-form-select
      v-model="items.options.items[index].typeCoin.value"
      :options="optionCoin"
      :state="items.options.items[index].typeCoin.value=== 0 ? false : true"
    />
      </b-form-group>
      </b-col>
              <!-- Description -->
          <b-col cols="12">
            <b-form-group
              :label="$t('challangeregister.description')">
              <b-form-textarea
              v-model="items.options.items[index].description"
              size="3"
              :placeholder="$t('challangeregister.description')"
              :state="items.options.items[index].description==''?false:true"/>
            </b-form-group>
          </b-col>
           <!--b-col lg="6" md="6" sm="12">
            <b-form-group
              :label="$t('challangeregister.descriptiones')">
              <b-form-textarea
              v-model="items.options.items[index].descriptiones"
              size="3"
              :placeholder="$t('challangeregister.descriptiones')"
              :state="items.options.items[index].descriptiones==''?false:true"/>
            </b-form-group>
          </b-col-->

    
          <!-- Remove Button -->
          <b-col cols="12">
            <div class="text-right">
            <b-button
              v-ripple.400="'rgba(234, 84, 85, 0.15)'"
              variant="outline-danger"
              class="mt-0 mt-md-2"
              @click="removeItem(index)"
            >
              <feather-icon
                icon="XIcon"
                class="mr-25"
              />
              <span>{{$t('challangeregister.delete')}}</span>
            </b-button>
            </div>
          </b-col>
          <b-col cols="12">
            <hr>
          </b-col>
        </b-row>
      </b-form>
      <div class="text-right">
    <b-button
      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
      variant="outline-primary"
      @click="repeateAgain"
    >
      <feather-icon
        icon="PlusIcon"
        class="mr-25"
      />
      <span>{{$t('challangeregister.additem')}}</span>
    </b-button>
    </div>
    <div class="text-center">
     <b-button
      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
      variant="success"
      @click="insertItems"
      v-if="button==1"
    >
      <feather-icon
        icon="SaveIcon"
        class="mr-25"
      />
      <span>{{$t('challangeregister.save')}}</span>
    </b-button>
    </div>
            </b-card-body>
        </b-card>
         <b-modal
         ref="modalid"
      title="Save URL"
      ok-only
      ok-title="Save"
      @ok="saveUrl"
    >
        <b-form-group v-if="modal==true">
          <label for="email">Insert URL</label>
          <b-form-input
            type="text"
            v-model="urlx"
            placeholder="URL Address"
          />
        </b-form-group>
    </b-modal>
       <b-modal
       v-model="opencat"
      title="Save Category"
      ok-only
      ok-title="Save"
      @ok="saveCategory"
      no-close-on-backdrop
      no-close-on-esc
      hide-header-close 
    >
    <b-form ref="formd" @submit.stop.prevent>
       <b-form-group label="Select a Category" :state="selectedcat==null?false:true"
       invalid-feedback="Select a category">
        <v-select
          v-model="selectedcat"
          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
          label="title"
          :options="optionscat"
          :searchable="false"
          :state="selectedcat==null?false:true"
          required
        />
      </b-form-group>
    </b-form>
    </b-modal>
    </div>
</template>
<script>
import {BCard,BCardHeader,BCardBody,BCardTitle,BTable,BButton,BForm,BFormGroup,BRow,BCol,BFormInput,
BFormTextarea,BFormFile,BFormInvalidFeedback,BFormSelect,BModal,BBadge,BSpinner} from "bootstrap-vue" 
import SellerService from "@/services/SellerService";
import Ripple from "vue-ripple-directive"
import { heightTransition } from '@core/mixins/ui/transition'
import vSelect from 'vue-select'
export default {
    directives:{Ripple},
    components:{BCard,BCardHeader,BCardTitle,BTable,BButton,BForm,BFormGroup,BRow,BCol,BFormInput,BCardBody,BFormTextarea,
    BFormFile,BFormInvalidFeedback,BFormSelect,vSelect,BModal,BBadge,BSpinner},
     mixins: [heightTransition],
    data(){

        return{
            fields:[{label:this.$t("curlang")=="en"?"Quest Title":"Titulo Desafio",key:"options.title"},
            {label:this.$t("curlang")=="en"?"Quest Description":"Descripcion Desafio",key:"options.description"},
            {label:this.$t("curlang")=="en"?'Status Quest':'Estado del Desafio',key:"options.items.length"}],
            quests:[],
            seeItem:false,
            BaritemTitle:"",
            changeQuest:0,
            button:1,
           option: [{ text: 'Select a Type',value:0 },{ text: 'Open Url',value:1 },{text:'Upload Image',value:2},
           {text:'Copy Comment',value:3},{text:'Upload URL',value:4}],
            optionCoin: [{ text: 'Select a Coin',value:0 },{ text: 'Crowns',value:1 },{text:'Goblets',value:2}],
            items:{},
             nextTodoId:1,
             modal:false,
             currentIndex:-1,
             urlx:null,
             busy:true,
             optionscat:[],
             selectedcat:null,
             opencat:false
        }
    },
    mounted(){
        this.getQuests()
        this.getCategories()
    },
    methods:{
         repeateAgain(){
      this.items.options.items.push({
        id: this.items.options.items.length+1 ,
        title: "",
        titlees:"",
        headerUrl:null,
        description:"",
        descriptiones:"",
        rewards:"",
        isActive:true,
        type:{value:0},
        typeCoin:{value:0},
        lastLength:-1
      })
       this.$nextTick(() => {
        this.trAddHeight(this.$refs.row[0].offsetHeight)
      })
      if (this.items.options.items.length>0) {
        this.button=1
      }
    },
    removeItem(index) {
      this.items.options.items.splice(index, 1)
      this.trTrimHeight(this.$refs.row[0].offsetHeight)
      if (this.items.options.items.length==0) {
        this.button=0
      }
    },
    initTrHeight() {
      this.trSetHeight(null)
      this.$nextTick(() => {
        this.trSetHeight(this.$refs.form.scrollHeight)
      })
    },
      getQuests:async function(){
        const qu=(await SellerService.getQuests(JSON.parse(localStorage.getItem("auth")).discordId,0)).data
       this.quests=qu
        this.quests.sort((a, b) => {
          const dateA = new Date(a.options.dueDate);
             const dateB = new Date(b.options.dueDate);
             return dateB - dateA;
      });
       this.busy=false
      },
           getCategories:async function(){
      const ct=(await SellerService.getCategories(JSON.parse(localStorage.getItem("auth")).discordId)).data
        for (let x = 0;x<ct.length;x++){
            this.optionscat.push({title:ct[x].category,value:ct[x]._id})
        }
        
    },
      getId:function(q){
        const olditems=this.items
        this.items=q
        if(olditems)
        {
          if( this.items._id!=olditems._id && (this.lastLength!=-1 && this.items.options.items.length!=this.lastLength)  ){
          olditems?.options?.items.splice(olditems?.options?.items?.length-1, 1)
        }
        }
        
        this.lastLength=this.items.options.items.length;
        this.changeQuest=1;
        const v=this.items.options.items.filter(item=>item.id==this.items.options.items.length)
        if(this.items.options.items.filter(item=>item.id==this.items.options.items.length).length==0)
        {
          this.items.options.items.push({
        id: this.items.options.items.length==0?1:this.items.options.items.length+1,
        title: "",
        titlees:"",
        headerUrl:null,
        description:"",
        descriptiones:"",
        rewards:"",
        isActive:true,
        type:{value:0},
         typeCoin:{value:0}
      });
        }
        
        this.seeItem=true
        this.BaritemTitle=q.options.title
        this.initTrHeight();

        this.changeQuest=2;
      },
         insertItems:async function(){
   
            const insertar=(await SellerService.insertQuestItem(JSON.parse(localStorage.getItem("auth")).discordId,
        this.items))
            this.$swal({
          title: "",
          text: "Quest Saved Successfull",
          icon: "success",
           allowOutsideClick: false,
          showClass: {
            popup: "animate__animated animate__fadeIn",
          },
          customClass: {
            confirmButton: "btn btn-success",
          },
          buttonsStyling: false,
        }).then(result => {
        if (result.value) {
                this.$router.go(0)
         }
      });
         },
          openModal:function(index){
      this.currentIndex=-1;
      if (this.items.options.items[index].type.value==1) {
        this.items.options.items[index].type.url=""
        this.currentIndex=index;
        this.modal=true;
      
          this.$refs["modalid"].show()
      }
      if (this.items.options.items[index].type.value==3) {
         this.items.options.items[index].type.category=""
        this.currentIndex=index;
        this.opencat=true 
      }
    },
    saveUrl:function(){
      if(this.urlx){
        this.items.options.items[this.currentIndex].type.url=this.urlx
      }
    },
     saveCategory:function(modal){    
      if (this.selectedcat) {
        this.items.options.items[this.currentIndex].type.category=this.selectedcat.title
        this.selectedcat=null
      }else{
        modal.preventDefault();
        

      }
      },
    },
}
</script>
<style lang="scss" scoped>
.repeater-form {
  overflow: hidden;
  transition: .35s height;
}
</style>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import "@core/scss/vue/libs/vue-sweetalert.scss";
</style>